/* Contact Us grid page starts */
.contactUsGrid {
  display: grid;
  grid-template-rows: unset;
  grid-template-columns: unset;
  min-height: 100vh;
}

.contactUsGrid>div {
  /* height: 100%; */
  /* text-align: center; */
  /* font-size: 30px; */
  overflow: hidden;

  /* you can delete the following, this border is just to track the grid style */
  /* border-style: solid ; */
  /* border-color: red; */
}

/* Contact Us grid page ends */

.hero-image-1 {
  background: url("../../assets/contact/contact-hero.png");
  background-size: cover;
  align-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.row {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 100px;
  width: 600px;
}

.row div {
  width: 100%;
}

.errorBox {
  border-color: rgba(255, 0, 0, 0.5);
  width: 95%;
  /* height: 2px; */
  padding: 5px;
  padding-top: 30px;
}

.errorTextColor {
  color: red;
  margin: 0px;
  font-size: 15px;
}

#containerPosition {
  width: 100%;
}

.formPosition {
  margin: auto;
  max-width: 643px;
  overflow: hidden;
  /*: table; */
}

.container-fluid {
  /* width: fit-content; */
  /* padding: 40px; */
  /* margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 100px; */
  margin: auto;
  width: 70%;
  padding-bottom: 100px;
  text-align: -webkit-center;
}

.container-fluid p {
  line-height: normal;
  text-align: start;
  margin-top: 5px;
}

.container-fluid h5 {
  line-height: normal;
  text-align: start;
  margin: 0px;
}

.contactHeader {
  /* height: 75px; */
  margin-top: 80px;
  margin-bottom: 80px;
  /* font-size: 45px; */
  color: black;
}

.inputField {
  border-bottom: 2px solid #A0A0A0;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
}

/* Added  */
.contact-top-bg {
  background: url('../../assets/contact/contact-hero.png');
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  min-height: 300px;
  /* max-height: 300px; */
}

.shade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(6 27 61 / 41%);
}

.contact-top-bg div {
  /* width: 500px;
  max-width: 500px;
  height: 300px; */
}

.contactUsGrid>div:nth-child(2) {
  border-bottom: solid 2px #000;
}

.contact-title {
  color: #000;
  /* font-size: 30px; */
  line-height: 40px;
}

h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin: 10px 0;
  font-size: 20px;

}

.grid-contact-text {
  /* font-size: 20px; */
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.t-s {
  margin-top: 20px;
}

.contact-info {
  padding-bottom: 60px;
}

select, input, textarea {
  font-family: 'Poppins', sans-serif;
  /* font-size: 20px; */
  width: 100%;
  margin-bottom: 20px;
  border: none;
  border-bottom: solid #A0A0A0;
  outline: none;
}

.c-r-2 div {
  max-width: 100%;
  min-width: 300px;
}

#contact-form ::placeholder, #contact-form select option:first-child {
  color: #b0b0b0 !important;
}

button.primary-btn {
  border: none;
  font-size: 20px;
  padding: 10px 55px;
  font-family: 'Playfair Display', serif;
}


.box-padding, .contact-box-padding {
  padding: 30px;
}

.contact-box-padding button.primary-btn {
  float: unset;
}

.honey {
  display: none;
}

.disabled {
  display: none;

}

.iframeContainer iframe {
  border: none;
}

@media (max-width: 908px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: auto auto;
    grid-gap: 50px;
    max-width: 400px;
  }
}

@media (max-width: 1366px) {
  .contactHeader {
    /* font-size: 35px; */
    color: black;
  }
}

@media screen and (max-width: 450px) {
  .formPosition {
    margin: auto;
    max-width: 643px;
    overflow: hidden;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .formPosition {
    /* margin-top: 20px; */
    margin: auto;
    max-width: 643px;
    overflow: hidden;
    margin-bottom: 50px;
  }
}


@media (max-width:768px) {

  .c-r-2 div {
    max-width: 45%;
    min-width: 300px;
  }

  .form-control {
    max-width: 300px;
    align-self: center;
  }

  .form-row {
    min-width: 300px;
    text-align-last: center;
  }

}

@media (min-width:768px) {

  .contactUsGrid>div:nth-child(2) {
    border-bottom: none;
  }

  .contact-title {
    /* font-size: 45px;
    line-height: 60px; */
    /* text-align-last: center; */
  }

  .contactUsGrid .box-padding {
    padding: 90px;
  }

  .contact-box-padding {
    padding: 60px;
  }

  /* .contactUsGrid  .contact-info .box-padding {
    padding: 70px;
  } */

  .c-r-2 div {
    max-width: 45%;
    /* min-width: 300px; */
  }

  .contact-box-padding button.primary-btn {
    float: right;
  }

  .c-r-2.space-even {
    justify-content: space-between;
  }

  .contactUsGrid {
    grid-template-columns: 50% 50%;
    grid-template-rows: unset;
  }

}

@media (max-width: 1300px) {
  .c-r-2 input {
    /* width: 80%; */
  }
}