@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&family=Cormorant+Garamond:wght@300;400;500;600&family=Montserrat:wght@400;700&family=Pacifico&family=Playfair+Display&family=Quicksand:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&family=Cormorant+Garamond:wght@300;400;500;600&family=Montserrat:wght@400;700&family=Pacifico&family=Playfair+Display&family=Quicksand:wght@300;400;500;600;700&display=swap');


body, span {
  margin: 0;
  font-family: 'Assistant', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #353535;
  font-size: 18px;
  line-height: 30px;
  scroll-behavior: smooth;
}


html {
  scroll-behavior: smooth;
}


h1 {
  font-family: Garamond;
  font-weight: 300;
  /* font-size: 64; */
  color: #fff;
  font-size: 2.8em;
  line-height: 1em;
  text-align: center;
}

h2, h3, h4 {
  font-family: Garamond;
  font-weight: 300;
  font-size: 2em;
  line-height: 1em;
}

img {
  width: 100%;
}

.contianer {
  max-width: 1920px;
  margin: 0 auto;
}

.box-padding {
  padding: 20px;
}

.flexy {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.space-even {
  justify-content: space-evenly;
}

.space-between {
  justify-content: center;
}

.center-text {
  text-align: center;
}

.primary-btn {
  cursor: pointer;
  padding: 5px 25px;
  background: #B82E10;
  transition: all 0.4s ease;
  color: #fff;
  text-decoration: none;
}

.primary-btn:hover {
  background: #000;
}

@media (min-width:1100px) {
  body, body p, body span {
    font-size: 1em;
    /* font-family: Garamond; */
  }
}

@media (min-width:768px) {
  /* .box-padding {
    padding: 30px;
  } */

  .space-between {
    justify-content: space-between;
  }
  

}