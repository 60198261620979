video {
    object-fit: cover;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    transition: opacity ease-in-out 500ms;
}

.video-show {
    opacity: 1;
}


#hm-hero {
    position: relative;
    height: 55vh;
    justify-content: center;
    align-items: center;
    padding: 20px;
    overflow: hidden;
    background-color: black;
    transition-timing-function: ease-in-out;
    transition-delay: 500ms;
    transition-property: opacity;
}



.hm-hero-title {
    max-width: 1200px;
    line-height: 55px;
    z-index: 33;
}

@media screen and (max-width: 500px) {
    .hm-hero-title {
        max-width: 1200px;
        font-size: xx-large;
        line-height: 55px;
    }
    
}

/* hero arrow button starts */
.hero__scroll-btn {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    z-index: 22;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    scroll-behavior: smooth;
}

.hero__scroll-btn:hover {
    color: white;
    opacity: 0.8;
}

.hero__scroll-btn .bi {
    transition-delay: 0.5s;
    animation: bounce 1s infinite alternate;
}

@keyframes bounce {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(-10px);
    }
}

/* hero arrow button ends */

#hm-about .flexy {
    justify-content: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.about-text {
    width: 600px;
    text-align: center;
}

.about-text p {
    /* width: 600px; */
    text-align: start;
}


.about-img {
    max-width: 600px;
    max-height: 350px;
    object-fit: cover;
    overflow: hidden;
}

.about-img img {
    width: 100%;
    height: 90%;
    margin-top: 20px;
}

#hm-service {
    background: #f4f4f4;
}

.service-img {
    background: url('../../assets/home/hm-about.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    min-height: 360px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}


.service-img h2 {
    width: 75%;
    z-index: 555;
}

@media screen and (max-width: 500px) {
    .service-img h2 {
        width: 75%;
        z-index: 555;
        font-size: x-large;
    }
}

.shade {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(6 27 61 / 52%);
}

.service-content {
    /* max-width: 980px; */
    padding: 20px;
    width: 100%;
    background: #f4f4f4;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
}

.serivce-body {
    max-width: 700px;
    margin-top: 30px;
}

.serivce-title {
    margin-bottom: 20px;
    text-align: center;
}

.serivce-1 {
    flex-wrap: wrap;
    padding: 20px;
    font-size: 0.95em;
    border-bottom: 1px solid #353535;
}

.serivce-1:last-child {
    border-bottom: none;
}

.service-img-container {
    min-width: 200px;
    align-self: center;
}

.service-img-container img {
    display: flex;
    max-width: 80px;
    max-height: 70px;
    margin: 0 auto;
}

#hm-body {
    justify-content: space-around;
    margin: 0 auto;
}

#hm-footer {
        justify-content: center;
        position: relative;
        padding: 20px;
        min-height: 300px;
        /* background-position-y: -145px; */
        background-repeat: no-repeat;
        align-items: center;
        background-attachment: fixed;
        background-size: cover;
        background-image: url('../../assets/home/hm-upper-footer-4.jpg');
        margin-top: 50px;
        margin-bottom: 50px;
    
}

@media screen and (max-width: 500px) {
    #hm-footer {
        justify-content: center;
        position: relative;
        padding: 20px;
        min-height: 300px;
        background-repeat: no-repeat;
        align-items: center;
        background-size: cover;
        background-image: url(/static/media/hm-upper-footer-4.ab0483cb.jpg);
        margin-top: 50px;
        margin-bottom: 50px;
        background-attachment:unset;
    }
}

#hm-footer h2, #hm-invest h2, .hm-hero-title, .service-img h2 {
    color: #fff;
}

@media screen and (max-width: 500px) {
    #hm-footer h2 {
        color: #fff;
        font-size: x-large;
    }
}
#hm-invest {
    background: #f4f4f4;
}

#hm-invest .img-text {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1120px;
    min-height: 300px;
    background-position: center;
    background: url('../../assets/home/hm-invest.png');
}

#hm-invest .img-text h2 {
    position: relative;
}

#hm-invest .img-text:before {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: inherit;
    content: ' ';
    background-color: rgba(6, 27, 61, 0.602);
}


#hm-invest #invest-text {
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
}

#invest-text .primary-btn {
    margin: 0 auto;
}

#hm-service .flexy, .flexy {
    flex-wrap: wrap;
    /* place-content: center; */
}

#invest-text .primary-btn {
    margin-top: 20px;
}

.footer-banner-text {
    text-align: center;
    max-width: 1060px;
    z-index: 333;
}

.small-para {
    max-width: 620px;
}

.small-para p {
    padding: 10px;
    max-width: 500px;
}

@media (min-width: 1290px) {
    #hm-about .flexy {
        flex-wrap: nowrap;
        justify-content: space-evenly;
    }
    .serivce-body {
        margin-left: 30px;
        
    }
    .service-content {
        padding: 60px;
    }
}

@media (max-width: 1280px) {
    .about-text p {
        text-align: start;
    }

    .about-img {
        max-width: 500px;
        max-height: 350px;
        object-fit: cover;
        overflow: hidden;
    }

    .about-text {
        width: 500px;
        text-align: center;
    }

}

@media (min-width: 1080px) {
    #hm-service .flexy, .flexy {
        flex-wrap: nowrap;
    }

    #hm-body>div {
        min-width: 500px;
    }

    .service-img {
        max-width: 600px;
    }
}