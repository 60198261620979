@import url(https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&family=Cormorant+Garamond:wght@300;400;500;600&family=Montserrat:wght@400;700&family=Pacifico&family=Playfair+Display&family=Quicksand:wght@300;400;500;600;700&display=swap);
body, span {
  margin: 0;
  font-family: 'Assistant', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #353535;
  font-size: 18px;
  line-height: 30px;
  scroll-behavior: smooth;
}


html {
  scroll-behavior: smooth;
}


h1 {
  font-family: Garamond;
  font-weight: 300;
  /* font-size: 64; */
  color: #fff;
  font-size: 2.8em;
  line-height: 1em;
  text-align: center;
}

h2, h3, h4 {
  font-family: Garamond;
  font-weight: 300;
  font-size: 2em;
  line-height: 1em;
}

img {
  width: 100%;
}

.contianer {
  max-width: 1920px;
  margin: 0 auto;
}

.box-padding {
  padding: 20px;
}

.flexy {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.space-even {
  justify-content: space-evenly;
}

.space-between {
  justify-content: center;
}

.center-text {
  text-align: center;
}

.primary-btn {
  cursor: pointer;
  padding: 5px 25px;
  background: #B82E10;
  transition: all 0.4s ease;
  color: #fff;
  text-decoration: none;
}

.primary-btn:hover {
  background: #000;
}

@media (min-width:1100px) {
  body, body p, body span {
    font-size: 1em;
    /* font-family: Garamond; */
  }
}

@media (min-width:768px) {
  /* .box-padding {
    padding: 30px;
  } */

  .space-between {
    justify-content: space-between;
  }
  

}
  .logo {
    /* font-weight: bold; */
    color: var(--heading-color);
    letter-spacing: 1px;
    /* display: flex; */
    font-size: 25px;
    display: inline-flex;
    align-items: center;
    margin-right: auto;
    color: white;
  }

  .header-logo{
    position: relative;
  }

  .header-logo img { 
    width: 146px;
    position: relative;
    margin-left: 30px;
    margin-top: 10px;
  }


  header {
    background-color: #fff;
    text-align:center;
    width: 100%;
    z-index: 99;
  }

  /* hamburder css starts */

  .nav-toggle {
    display: none;
  }

  .nav-toggle-label {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 1em;
    height: 56px;
    display: flex;
    align-items: center;
  }

  /* .nav-toggle-label span = middle line */
  /* .nav-toggle-label span:: before = top line */
  /* .nav-toggle-label span:: after = bottom line */
  .nav-toggle-label span,
  .nav-toggle-label span::before,
  .nav-toggle-label span::after {
    display: block;
    background: #1a3554;
    height: 2px;
    width: 1.5em;
    border-radius: 2px;
    position: relative;
  }

  .nav-toggle-label span::before,
  .nav-toggle-label span::after {
    content: '';
    position: absolute;
  }

  .nav-toggle-label span::before {
    bottom: 7px;
  }

  .nav-toggle-label span::after {
    top: 7px;
  }

  /* hamburder css ends */

  /* nav css starts */

  nav {
    position: absolute;
    text-align: left;
    z-index: 1000;
    left: 0;
    background: #fff;
    width: 100%;
    /* initially set this with a width of 1 (100%) and 
    a height of 0, later when we toggle using nav-toggle checked,
    it will transform this to 1, 1 */
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: top;
            transform-origin: top;
    transition: -webkit-transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
  }

  nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    justify-content: flex-end;
  }

  nav li {
    margin-bottom: 1em;
    margin-left: 1em;
  }

  nav a {
    color: #1a3554;
    text-decoration: none;
    /* initially the opacity of the links are 0, later we 
    transition/fade them in */
    opacity: 0;
    /* upon click, the links fade in 250ms but they also have 
    a delay of 250ms (hence the 2nd 250ms we added) */
    transition: all .4s ease-in-out;
    border-bottom:#fff solid 1px;
  }

  nav a:hover {
    border-bottom:#1a3554 solid 1px;
    color: rgb(73, 73, 73);
  }

  /* the ~ looks for any preceeding sibling after (in this case it is the <nav> */
  .nav-toggle:checked ~ nav {
    -webkit-transform: scale(1,1);
            transform: scale(1,1)
  }

  .nav-toggle:checked ~ nav a {
    opacity: 1;
    transition: opacity 250ms ease-in-out 250ms;
    /* display: block; */
  }




  /* From here on out, the css styling is for bigger screens (non hamburger view) */

  @media screen and (min-width: 800px) {
    .nav-toggle-label {
      display: none;
    }

    header {
      display: grid;
      /* we setup 4 columns 
        1. 1fr
        2. auto
        3. minmax()
        4. 1fr
      */
      grid-template-columns: auto auto minmax(600px, 1fr) auto;
    }

    .logo {
      /* the logo starts in column 2 and spans over to the next col */
      grid-column: 2 / span 1;
      margin-left: 50px;
    }

    nav {
      /* all: unset undoes all the mobile css above */
      all: unset;
      grid-column: 3 / 4;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    nav ul {
      display: flex;
      margin-right: 50px;
      /* justify-content: flex-end;  */
    }

    nav a {
      opacity: 1;
    }

    nav li {
      margin-bottom: 0;
    }

  }
#footer {
    /* justify-content: space-evenly;
    padding: 10px;
    background: #000;
    align-items: center; */
    /* display: grid;
    grid-template-columns: auto, auto, auto;
    grid-template-columns: 25% 50% 25%; */
    padding: 10px;
    background: #000;
    align-items: center;
}


.footer-icon {
    max-width: 30px;
    max-height: 30px;
    padding: 15px;
    cursor: pointer;
    justify-self: center;
}

.copy {
    padding: 5px;
}

.copy a {
    color: #353535;
    text-decoration: none;
}
.copy a:hover {
    color: #c1c1c1;
}

.sub-footer {
    border-top: 1px solid #353535
}

.pdf-btn {
    cursor: pointer;
    padding: 5px 25px;
    background: #B82E10;
    transition: all 0.4s ease;
    color: #fff;
    max-width: 30%;
    place-self: center;
    text-align: center;
    text-decoration: none;
}

.footer-icons {
    margin-top: 30px;
}

.footer-icon img {
    width: 35px;
    max-width: 35px;
    max-height: 35px;
}

.left-foot, .right-foot {
    width: 100%;
}

#footer .logo {
    margin-left:0;
    border-bottom: 1px solid #fff;
}

#footer h3, #footer p {
    color: #fff;
    /* padding-left: 20px; */
}

#footer h3 {
    border-bottom: 1px solid #fff;
    padding-right: 30px;
}

.right-foot {
    border-top: 1px solid rgb(169, 169, 169);
    margin-bottom: 10px;
    display: -ms-inline-grid;
}

@media screen and (max-width: 500px) {
    .right-foot {
        border-top: 1px solid rgb(169, 169, 169);
        margin-bottom: 10px;
        display: -ms-inline-grid;
        text-align: center;
    }

    .paradigm-logo {
        max-width: 90%;
        place-self: center;
        padding: 30px;
    }
    
}

.paradigm-logo {
    max-width: 30%;
    place-self: center;
}

@media screen and (max-width: 800px) {

    .paradigm-logo {
        max-width: 90%;
        place-self: center;
        padding: 30px;
    }
    
}

#footer .left-img img {
    max-width: 20px; 
    max-height: 20px;
    margin-right: 20px;
}

#footer .left-img {
    align-items: center;
}

#footer .left-img , #footer .left-img a {
    color: #fff;
    text-decoration: none;
}

#footer .left-img p {
    min-width: 310px;
    margin: 0;
}

@media screen and (max-width: 1080px) {
    .pdf-btn {
        cursor: pointer;
        padding: 11px;
        background: #B82E10;
        transition: all 0.4s ease;
        color: #fff;
        margin: 13px;
        max-height: 50%;
        /* width: 20%; */
        /* place-self: center; */
        /* text-align: center; */
        text-decoration: none;
    }

    #footer {
        justify-content: space-evenly;
        /* grid-template-columns: auto, auto, auto; */
        /* grid-template-columns: 25% 50% 25%; */
        padding: 10px;
        background: #000;
        align-items: center;
    }
}

@media (min-width:768px) {
    .copy {
        padding: 15px;
    }

    .left-foot, .right-foot {
        width: 50%;
    }
    .right-foot {
        border-left: 1px solid rgb(169, 169, 169);
        border-top: none;
        display: grid;
    }
}




video {
    object-fit: cover;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    transition: opacity ease-in-out 500ms;
}

.video-show {
    opacity: 1;
}


#hm-hero {
    position: relative;
    height: 55vh;
    justify-content: center;
    align-items: center;
    padding: 20px;
    overflow: hidden;
    background-color: black;
    transition-timing-function: ease-in-out;
    transition-delay: 500ms;
    transition-property: opacity;
}



.hm-hero-title {
    max-width: 1200px;
    line-height: 55px;
    z-index: 33;
}

@media screen and (max-width: 500px) {
    .hm-hero-title {
        max-width: 1200px;
        font-size: xx-large;
        line-height: 55px;
    }
    
}

/* hero arrow button starts */
.hero__scroll-btn {
    position: absolute;
    left: 50%;
    bottom: 30px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 22;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    scroll-behavior: smooth;
}

.hero__scroll-btn:hover {
    color: white;
    opacity: 0.8;
}

.hero__scroll-btn .bi {
    transition-delay: 0.5s;
    -webkit-animation: bounce 1s infinite alternate;
            animation: bounce 1s infinite alternate;
}

@-webkit-keyframes bounce {
    from {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

    to {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
}

@keyframes bounce {
    from {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

    to {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
}

/* hero arrow button ends */

#hm-about .flexy {
    justify-content: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.about-text {
    width: 600px;
    text-align: center;
}

.about-text p {
    /* width: 600px; */
    text-align: start;
}


.about-img {
    max-width: 600px;
    max-height: 350px;
    object-fit: cover;
    overflow: hidden;
}

.about-img img {
    width: 100%;
    height: 90%;
    margin-top: 20px;
}

#hm-service {
    background: #f4f4f4;
}

.service-img {
    background: url(/static/media/hm-about.c7b8dbcd.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    min-height: 360px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}


.service-img h2 {
    width: 75%;
    z-index: 555;
}

@media screen and (max-width: 500px) {
    .service-img h2 {
        width: 75%;
        z-index: 555;
        font-size: x-large;
    }
}

.shade {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(6 27 61 / 52%);
}

.service-content {
    /* max-width: 980px; */
    padding: 20px;
    width: 100%;
    background: #f4f4f4;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
}

.serivce-body {
    max-width: 700px;
    margin-top: 30px;
}

.serivce-title {
    margin-bottom: 20px;
    text-align: center;
}

.serivce-1 {
    flex-wrap: wrap;
    padding: 20px;
    font-size: 0.95em;
    border-bottom: 1px solid #353535;
}

.serivce-1:last-child {
    border-bottom: none;
}

.service-img-container {
    min-width: 200px;
    align-self: center;
}

.service-img-container img {
    display: flex;
    max-width: 80px;
    max-height: 70px;
    margin: 0 auto;
}

#hm-body {
    justify-content: space-around;
    margin: 0 auto;
}

#hm-footer {
        justify-content: center;
        position: relative;
        padding: 20px;
        min-height: 300px;
        /* background-position-y: -145px; */
        background-repeat: no-repeat;
        align-items: center;
        background-attachment: fixed;
        background-size: cover;
        background-image: url(/static/media/hm-upper-footer-4.ab0483cb.jpg);
        margin-top: 50px;
        margin-bottom: 50px;
    
}

@media screen and (max-width: 500px) {
    #hm-footer {
        justify-content: center;
        position: relative;
        padding: 20px;
        min-height: 300px;
        background-repeat: no-repeat;
        align-items: center;
        background-size: cover;
        background-image: url(/static/media/hm-upper-footer-4.ab0483cb.jpg);
        margin-top: 50px;
        margin-bottom: 50px;
        background-attachment:unset;
    }
}

#hm-footer h2, #hm-invest h2, .hm-hero-title, .service-img h2 {
    color: #fff;
}

@media screen and (max-width: 500px) {
    #hm-footer h2 {
        color: #fff;
        font-size: x-large;
    }
}
#hm-invest {
    background: #f4f4f4;
}

#hm-invest .img-text {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1120px;
    min-height: 300px;
    background-position: center;
    background: url(/static/media/hm-invest.57925f8d.png);
}

#hm-invest .img-text h2 {
    position: relative;
}

#hm-invest .img-text:before {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: inherit;
    content: ' ';
    background-color: rgba(6, 27, 61, 0.602);
}


#hm-invest #invest-text {
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
}

#invest-text .primary-btn {
    margin: 0 auto;
}

#hm-service .flexy, .flexy {
    flex-wrap: wrap;
    /* place-content: center; */
}

#invest-text .primary-btn {
    margin-top: 20px;
}

.footer-banner-text {
    text-align: center;
    max-width: 1060px;
    z-index: 333;
}

.small-para {
    max-width: 620px;
}

.small-para p {
    padding: 10px;
    max-width: 500px;
}

@media (min-width: 1290px) {
    #hm-about .flexy {
        flex-wrap: nowrap;
        justify-content: space-evenly;
    }
    .serivce-body {
        margin-left: 30px;
        
    }
    .service-content {
        padding: 60px;
    }
}

@media (max-width: 1280px) {
    .about-text p {
        text-align: start;
    }

    .about-img {
        max-width: 500px;
        max-height: 350px;
        object-fit: cover;
        overflow: hidden;
    }

    .about-text {
        width: 500px;
        text-align: center;
    }

}

@media (min-width: 1080px) {
    #hm-service .flexy, .flexy {
        flex-wrap: nowrap;
    }

    #hm-body>div {
        min-width: 500px;
    }

    .service-img {
        max-width: 600px;
    }
}
/* Intro img */
.investment-hero-img {
    background: url(/static/media/auto.8d997afa.jpg);
    position: relative;
    background-size: cover;
    background-position-y: -201px;
    background-attachment: fixed;
    /* background-position: -5px; */
    align-content: center;
    text-align: center;
    width: 100%;
    height: 55vh;
    overflow: hidden;
    background-color: black;
}

@media screen and (max-width: 500px) {
    .investment-hero-img {
        background: url(/static/media/auto.8d997afa.jpg);
            position: relative;
            background-size: cover;
            text-align: center;
            background-position-y: 0px;
            background-position-x: -361px;
            background-attachment: unset;
            height: 55vh;
            overflow: hidden;
            margin-bottom: 80px;
            background-color: black;
    }
    
}

.hero-title-header{
    /* position: relative; */
    text-align: center;
    color: white;
    /* border: 3px solid #73AD21; */
    width: 100%;
    height: 55vh;
}

.investment-hero-title {
    /* padding-top: 229px; */
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 3;
    /* font-size: 54px; */
}

@media screen and (max-width: 1800px) {
    .investment-hero-title {
        /* padding-top: 211px; */
        position: relative;
    }
}


@media screen and (max-width: 500px) {
    .investment-hero-title {
        /* padding-top: 218px; */
        position: relative;
        margin: auto;
        font-size: xx-large;
    }
}


/* intro text information section */
.intro-text-box {
    text-align: center;
    max-width: 70%;
    margin: auto;
    border-bottom-style: solid;
    border-color: rgb(19 63 128 / 76%);
}

@media screen and (max-width: 600px) {
    .intro-text-box {
        text-align: center;
        /* max-width: 40%; */
        margin: auto;
        border-bottom-style: solid;
        border-color: rgb(19 63 128 / 76%);
    }
}

.intro-text {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 22px;
}

.intro-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    grid-gap: 10px;
    padding: 90px;
}

.intro-icons p{
    width: 300px;
}

.intro-item {
    grid-row: 1 / 3;
    text-align: center;
  }

  .intro-item img{
    max-width: 92px;
    max-height: 92px;
  }


/* investment body hero pic */
.investment-body-img {
    background: url("/static/media/logistics(2).82774b03.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(6, 27, 61, 0.602);
    z-index: 1;
}

.shade1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(6, 27, 61, 0.602);
    /* z-index: 1; */
}



.body-point {
    display: flex;
    grid-template-columns: auto auto;
    flex-wrap: wrap;
    grid-gap: 10px;
    justify-content: center;
    padding: 100px;
}

@media screen and (max-width: 1280px) {
    .body-point {
        display: flex;
        grid-template-columns: auto auto;
        flex-wrap: wrap;
        grid-gap: 10px;
        justify-content: center;
        padding: 46px;
        
    }
    
}

.body-point > div {
    text-align: center;
    width: 560px;
    height: 67px;
    margin: 50px;
    font-size: 30px;
    /* background: #319EA5; */
    background: rgb(6 27 61);
    border-radius: 50px;
    min-width: 430px;
    position: relative;
    
    
}

@media (max-width: 1550px) {
    .body-point > div {
        text-align: center;
        width: 560px;
        height: 67px;
        margin: 10px;
        font-size: 30px;
        /* background: #319EA5; */
        background: rgb(6 27 61);
        border-radius: 50px;
        min-width: 430px;
        position: relative;
    }
}

@media screen and (max-width: 600px) {
    .body-point > div {
        text-align: center;
        height: 67px;
        margin: 10px;
        font-size: 30px;
        /* background: #319EA5; */
        background: rgb(6 27 61);
        border-radius: 50px;
        min-width: 300px;
        position: relative;
    }
}


.body-point p {
    width: 70%;
    padding-left: 90px;
    margin: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #ffffff;
    font-size: 20px;
    text-align: start;
}

@media screen and (max-width: 600px) {
    .body-point p {
        width: 70%;
        padding-left: 70px;
        margin: 0;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        color: #ffffff;
        font-size: 15px;
        text-align: start;
    }
}

.body-points-circle {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    /* background: #164B4F; */
    background: #a0a0a0;
    margin: 6px;
}

.check {
    width: 28px;
    padding-top: 15px;
}


/* Transaction focus sections */
.transaction-focus {
    text-align: -webkit-center;
    margin-top: 100px;
}

.transaction-focus > h2 {
    text-align: center;
}

@media screen and (max-width: 600px) {
    .transaction-focus > h2 {
        text-align: center;
        margin-bottom: 60px;
    }
}

.transaction-focus-points {
    display: flex;
    grid-template-columns: auto auto;
    flex-wrap: wrap;
    grid-gap: 30px;
    justify-content: center;
    max-width: 1450px;
    padding: 70px;
}

@media screen and (max-width: 1280px) {
    .transaction-focus-points {
        display: flex;
        grid-template-columns: auto auto;
        flex-wrap: wrap;
        grid-gap: 30px;
        justify-content: center;
        max-width: 1450px;
        padding: 10px;
    }
    
}

@media screen and (max-width: 600px) {
    .transaction-focus-points {
        display: flex;
        grid-template-columns: auto auto;
        flex-wrap: wrap;
        grid-gap: 30px;
        justify-content: center;
        max-width: 1450px;
        padding: 5px;
    }
}

.focus-points {
    width: 600px;
    height: 60px;
    margin-left: 50px;
    margin-right: 50px;
    position: relative;
}

@media (max-width: 1550px) {
    .focus-points {
        width: 500px;
        height: 60px;
        margin-left: 50px;
        margin-right: 50px;
        position: relative;
    }
}

.focus-points p {
    top: 50%;
    -webkit-transform: translateY(-60%);
            transform: translateY(-60%);
    font-size: 20px;
    text-align: start;
    margin-left: 50px;
    text-align: start;
}

.focus-points-circle {
    position: absolute;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    /* background: #17B227 */
}

.transaction-check {
    color: white;
    height: 16.08px;
    width: 16px;
    padding-top: 10px;
}

/* industry focus */
.industry-focus {
    margin-top: 100px;
    background: #f4f4f4;
}

.industry-focus > h2 {
    padding-top: 50px ;
    text-align: center;
}

.industry-focus > h5 {
    margin-top: 70px;
    text-align: center;
}

.industry-grid {
    padding-top: 50px;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    grid-gap: 60px;
    flex-wrap: wrap;
    padding-bottom: 100px;
}

@media screen and (max-width: 1080px) {
    .industry-grid {
        display: flex;
        grid-template-columns: auto auto auto;
        justify-content: center;
        grid-gap: 23px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }
}


.industry-icon-container {
    min-width: 200px;
    align-self: center;
    text-align: center;
}

@media screen and (max-width: 500px) {
    .industry-icon-container {
    min-width: 163px;
    align-self: center;
    text-align: center;
    }
}

.industry-icon-title {
    margin-bottom: 9px;
    text-align: center;
}

@media screen and (max-width: 500px) {
    .industry-icon-title {
        margin-bottom: 9px;
        font-size: 14px;
        text-align: center;
    }
    
}

.industry-icon-container > img {
    display: flex;
    max-width: 80px;
    max-height: 80px;
    margin: 0 auto;
}


.plain-card-3 {
    position: relative;
    /* background: #1c19c2; */
    /* background-image: url("../../assets/investment/industry focus/aerospace.png"); */
    width: 300px;
    height: 320px;
}

.blue-hue {
    position: relative;
    z-index: 5;
    width: 300px;
    height: 320px;
    /* background: linear-gradient(0deg, rgb(46 88 168 / 41%), rgba(46, 88, 168)); */
    background: linear-gradient(0deg, rgb(19 63 128 / 76%), rgb(19 63 128 / 88%));
}

.card-text {
    margin: auto;
    text-align: center;
    /* width: 1000%; */
    padding-top: 140px;
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    color: #FFFFFF;
}





/* The following CSS styling is to add the hovering effect where the card reveals into something else. 
Roman will let us know when we can go ahead with this card effect design, in the mean time we are sticking with
static cards */

/* .clip-card-container-3 {
    position: relative;
    clip-path: circle(0px at 0px 0px);
    transition: all 1s ease;
    width: 290px;
    height: 320px;
    padding-left: 10px; */
    /* border-radius: 5px; */
    /* For now this is just background color, later when we settle on pics replace with URL */
    /* background-color: aquamarine;
    background-size: cover;
} */

/* .plain-card-3:hover .clip-card-container-3 {
    clip-path: circle(100%);
} */

/* .card-details-3 {
    padding: 25px 0;
    font-weight: bold;
    font-size: 25px;
    color: rgb(17, 1, 1);
    
}

.outer-clip-details {
    font-weight: bold;
    font-size: 35px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 15px 25px;
    color: #353535;
    background-color: aquamarine;
    background-size: cover;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
 */

/* Contact Us grid page starts */
.contactUsGrid {
  display: grid;
  grid-template-rows: unset;
  grid-template-columns: unset;
  min-height: 100vh;
}

.contactUsGrid>div {
  /* height: 100%; */
  /* text-align: center; */
  /* font-size: 30px; */
  overflow: hidden;

  /* you can delete the following, this border is just to track the grid style */
  /* border-style: solid ; */
  /* border-color: red; */
}

/* Contact Us grid page ends */

.hero-image-1 {
  background: url(/static/media/contact-hero.5b026a6f.png);
  background-size: cover;
  align-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.row {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 100px;
  width: 600px;
}

.row div {
  width: 100%;
}

.errorBox {
  border-color: rgba(255, 0, 0, 0.5);
  width: 95%;
  /* height: 2px; */
  padding: 5px;
  padding-top: 30px;
}

.errorTextColor {
  color: red;
  margin: 0px;
  font-size: 15px;
}

#containerPosition {
  width: 100%;
}

.formPosition {
  margin: auto;
  max-width: 643px;
  overflow: hidden;
  /*: table; */
}

.container-fluid {
  /* width: fit-content; */
  /* padding: 40px; */
  /* margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 100px; */
  margin: auto;
  width: 70%;
  padding-bottom: 100px;
  text-align: -webkit-center;
}

.container-fluid p {
  line-height: normal;
  text-align: start;
  margin-top: 5px;
}

.container-fluid h5 {
  line-height: normal;
  text-align: start;
  margin: 0px;
}

.contactHeader {
  /* height: 75px; */
  margin-top: 80px;
  margin-bottom: 80px;
  /* font-size: 45px; */
  color: black;
}

.inputField {
  border-bottom: 2px solid #A0A0A0;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
}

/* Added  */
.contact-top-bg {
  background: url(/static/media/contact-hero.5b026a6f.png);
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  min-height: 300px;
  /* max-height: 300px; */
}

.shade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(6 27 61 / 41%);
}

.contact-top-bg div {
  /* width: 500px;
  max-width: 500px;
  height: 300px; */
}

.contactUsGrid>div:nth-child(2) {
  border-bottom: solid 2px #000;
}

.contact-title {
  color: #000;
  /* font-size: 30px; */
  line-height: 40px;
}

h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin: 10px 0;
  font-size: 20px;

}

.grid-contact-text {
  /* font-size: 20px; */
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.t-s {
  margin-top: 20px;
}

.contact-info {
  padding-bottom: 60px;
}

select, input, textarea {
  font-family: 'Poppins', sans-serif;
  /* font-size: 20px; */
  width: 100%;
  margin-bottom: 20px;
  border: none;
  border-bottom: solid #A0A0A0;
  outline: none;
}

.c-r-2 div {
  max-width: 100%;
  min-width: 300px;
}

#contact-form ::-webkit-input-placeholder {
  color: #b0b0b0 !important;
}

#contact-form :-ms-input-placeholder {
  color: #b0b0b0 !important;
}

#contact-form ::placeholder, #contact-form select option:first-child {
  color: #b0b0b0 !important;
}

button.primary-btn {
  border: none;
  font-size: 20px;
  padding: 10px 55px;
  font-family: 'Playfair Display', serif;
}


.box-padding, .contact-box-padding {
  padding: 30px;
}

.contact-box-padding button.primary-btn {
  float: unset;
}

.honey {
  display: none;
}

.disabled {
  display: none;

}

.iframeContainer iframe {
  border: none;
}

@media (max-width: 908px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: auto auto;
    grid-gap: 50px;
    max-width: 400px;
  }
}

@media (max-width: 1366px) {
  .contactHeader {
    /* font-size: 35px; */
    color: black;
  }
}

@media screen and (max-width: 450px) {
  .formPosition {
    margin: auto;
    max-width: 643px;
    overflow: hidden;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .formPosition {
    /* margin-top: 20px; */
    margin: auto;
    max-width: 643px;
    overflow: hidden;
    margin-bottom: 50px;
  }
}


@media (max-width:768px) {

  .c-r-2 div {
    max-width: 45%;
    min-width: 300px;
  }

  .form-control {
    max-width: 300px;
    align-self: center;
  }

  .form-row {
    min-width: 300px;
    text-align-last: center;
  }

}

@media (min-width:768px) {

  .contactUsGrid>div:nth-child(2) {
    border-bottom: none;
  }

  .contact-title {
    /* font-size: 45px;
    line-height: 60px; */
    /* text-align-last: center; */
  }

  .contactUsGrid .box-padding {
    padding: 90px;
  }

  .contact-box-padding {
    padding: 60px;
  }

  /* .contactUsGrid  .contact-info .box-padding {
    padding: 70px;
  } */

  .c-r-2 div {
    max-width: 45%;
    /* min-width: 300px; */
  }

  .contact-box-padding button.primary-btn {
    float: right;
  }

  .c-r-2.space-even {
    justify-content: space-between;
  }

  .contactUsGrid {
    grid-template-columns: 50% 50%;
    grid-template-rows: unset;
  }

}

@media (max-width: 1300px) {
  .c-r-2 input {
    /* width: 80%; */
  }
}
.about-pg .contianer {
    max-width: 1150px;
    margin: 0 auto;
}

.about-header {
    width: 100%;
    border-bottom: 1px solid #000000;
}

.about-con {
    place-content: center;
}

.about-body-bg {
    margin-top: 30px;
    min-height: 50vh;
    text-align: center;
    background: #000000;
    color: #fff;
    background-image: url(/static/media/about-main-bg.0b3a93bd.png);
}


.about-body-bg span {
    color: #fff;
    font-size: 18px;
    
}

.about-body-bg h2 {
    text-align: center;
}

.about-body-bg .contianer {
    align-self: center;
    text-align:start;
}

.about-text-pg {
    padding: 0 40px 0 0;
}

.about-float-btn {
    text-align: center;
}

.about-list.space-between {
    justify-content: flex-start;
}

.about-hero-img {
    background-image: url("/static/media/la-homepage(1).32627a25.jpg");
    position: relative;
    background-size: cover;
    text-align: center;
    height: 55vh;
    background-position-y: -201px;
    background-attachment: fixed;
    overflow: hidden;
    margin-bottom: 80px;
}

@media screen and (max-width: 500px) {
    .about-hero-img {
        background-image: url("/static/media/la-homepage(1).32627a25.jpg");
        position: relative;
        background-size: cover;
        text-align: center;
        background-position-y: 0px;
        background-position-x: -100px;
        background-attachment:unset;
        height: 55vh;
        overflow: hidden;
        margin-bottom: 80px;
    }
}

.about-hero-shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(6, 27, 61, 0.602);
    z-index: 1;
}

.about-hero-title {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 3;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}



.about-list ul {
    list-style: none; 
    display: grid;
    /* grid-gap: 5px; */
    grid-template-columns: repeat(2, 1fr);
  }

  .about-list li {
    margin-right: 40px;
  }
  
  .about-list ul li::before {
    content: "\2022";  
    color: #154EA3;; 
    font-weight: bold; 
    display: inline-block; 
    width: 1em; 
    margin-left: -1em; 
    font-size: 30px;
    position: relative;
    top: 3px;
    /* margin-right: 0px; */
    
  }

  .about-bottom {
    margin:50px auto !important;
}
.about-bottom img {
    margin:50px auto !important;    
    /* max-width: 45%; */
    max-height: 39vh;
}

@media (min-width:768px) { 
    .beliefs-text {
        margin-left: 100px;
    }
}

@media (min-width:768px) {
    .about-pg .left, .about-pg .right  {
        max-width: 50%;
    }
    .about-bottom {
        margin:50px auto !important;
    }
    .about-bottom img {
        margin:50px auto !important;    
        max-width: 45%;
        max-height: 39vh;
    }

    .about-text-pg.left{ 
        padding-right:40px;
    }
    .about-list.space-between {
        justify-content: space-between;
    }
    
}

