#footer {
    /* justify-content: space-evenly;
    padding: 10px;
    background: #000;
    align-items: center; */
    /* display: grid;
    grid-template-columns: auto, auto, auto;
    grid-template-columns: 25% 50% 25%; */
    padding: 10px;
    background: #000;
    align-items: center;
}


.footer-icon {
    max-width: 30px;
    max-height: 30px;
    padding: 15px;
    cursor: pointer;
    justify-self: center;
}

.copy {
    padding: 5px;
}

.copy a {
    color: #353535;
    text-decoration: none;
}
.copy a:hover {
    color: #c1c1c1;
}

.sub-footer {
    border-top: 1px solid #353535
}

.pdf-btn {
    cursor: pointer;
    padding: 5px 25px;
    background: #B82E10;
    transition: all 0.4s ease;
    color: #fff;
    max-width: 30%;
    place-self: center;
    text-align: center;
    text-decoration: none;
}

.footer-icons {
    margin-top: 30px;
}

.footer-icon img {
    width: 35px;
    max-width: 35px;
    max-height: 35px;
}

.left-foot, .right-foot {
    width: 100%;
}

#footer .logo {
    margin-left:0;
    border-bottom: 1px solid #fff;
}

#footer h3, #footer p {
    color: #fff;
    /* padding-left: 20px; */
}

#footer h3 {
    border-bottom: 1px solid #fff;
    padding-right: 30px;
}

.right-foot {
    border-top: 1px solid rgb(169, 169, 169);
    margin-bottom: 10px;
    display: -ms-inline-grid;
}

@media screen and (max-width: 500px) {
    .right-foot {
        border-top: 1px solid rgb(169, 169, 169);
        margin-bottom: 10px;
        display: -ms-inline-grid;
        text-align: center;
    }

    .paradigm-logo {
        max-width: 90%;
        place-self: center;
        padding: 30px;
    }
    
}

.paradigm-logo {
    max-width: 30%;
    place-self: center;
}

@media screen and (max-width: 800px) {

    .paradigm-logo {
        max-width: 90%;
        place-self: center;
        padding: 30px;
    }
    
}

#footer .left-img img {
    max-width: 20px; 
    max-height: 20px;
    margin-right: 20px;
}

#footer .left-img {
    align-items: center;
}

#footer .left-img , #footer .left-img a {
    color: #fff;
    text-decoration: none;
}

#footer .left-img p {
    min-width: 310px;
    margin: 0;
}

@media screen and (max-width: 1080px) {
    .pdf-btn {
        cursor: pointer;
        padding: 11px;
        background: #B82E10;
        transition: all 0.4s ease;
        color: #fff;
        margin: 13px;
        max-height: 50%;
        /* width: 20%; */
        /* place-self: center; */
        /* text-align: center; */
        text-decoration: none;
    }

    #footer {
        justify-content: space-evenly;
        /* grid-template-columns: auto, auto, auto; */
        /* grid-template-columns: 25% 50% 25%; */
        padding: 10px;
        background: #000;
        align-items: center;
    }
}

@media (min-width:768px) {
    .copy {
        padding: 15px;
    }

    .left-foot, .right-foot {
        width: 50%;
    }
    .right-foot {
        border-left: 1px solid rgb(169, 169, 169);
        border-top: none;
        display: grid;
    }
}