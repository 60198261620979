/* Intro img */
.investment-hero-img {
    background: url("../../assets/investment/auto.jpg");
    position: relative;
    background-size: cover;
    background-position-y: -201px;
    background-attachment: fixed;
    /* background-position: -5px; */
    align-content: center;
    text-align: center;
    width: 100%;
    height: 55vh;
    overflow: hidden;
    background-color: black;
}

@media screen and (max-width: 500px) {
    .investment-hero-img {
        background: url("../../assets/investment/auto.jpg");
            position: relative;
            background-size: cover;
            text-align: center;
            background-position-y: 0px;
            background-position-x: -361px;
            background-attachment: unset;
            height: 55vh;
            overflow: hidden;
            margin-bottom: 80px;
            background-color: black;
    }
    
}

.hero-title-header{
    /* position: relative; */
    text-align: center;
    color: white;
    /* border: 3px solid #73AD21; */
    width: 100%;
    height: 55vh;
}

.investment-hero-title {
    /* padding-top: 229px; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    /* font-size: 54px; */
}

@media screen and (max-width: 1800px) {
    .investment-hero-title {
        /* padding-top: 211px; */
        position: relative;
    }
}


@media screen and (max-width: 500px) {
    .investment-hero-title {
        /* padding-top: 218px; */
        position: relative;
        margin: auto;
        font-size: xx-large;
    }
}


/* intro text information section */
.intro-text-box {
    text-align: center;
    max-width: 70%;
    margin: auto;
    border-bottom-style: solid;
    border-color: rgb(19 63 128 / 76%);
}

@media screen and (max-width: 600px) {
    .intro-text-box {
        text-align: center;
        /* max-width: 40%; */
        margin: auto;
        border-bottom-style: solid;
        border-color: rgb(19 63 128 / 76%);
    }
}

.intro-text {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 22px;
}

.intro-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    grid-gap: 10px;
    padding: 90px;
}

.intro-icons p{
    width: 300px;
}

.intro-item {
    grid-row: 1 / 3;
    text-align: center;
  }

  .intro-item img{
    max-width: 92px;
    max-height: 92px;
  }


/* investment body hero pic */
.investment-body-img {
    background: url("../../assets/investment/industry-focus/logistics\(2\).jpg");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(6, 27, 61, 0.602);
    z-index: 1;
}

.shade1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(6, 27, 61, 0.602);
    /* z-index: 1; */
}



.body-point {
    display: flex;
    grid-template-columns: auto auto;
    flex-wrap: wrap;
    grid-gap: 10px;
    justify-content: center;
    padding: 100px;
}

@media screen and (max-width: 1280px) {
    .body-point {
        display: flex;
        grid-template-columns: auto auto;
        flex-wrap: wrap;
        grid-gap: 10px;
        justify-content: center;
        padding: 46px;
        
    }
    
}

.body-point > div {
    text-align: center;
    width: 560px;
    height: 67px;
    margin: 50px;
    font-size: 30px;
    /* background: #319EA5; */
    background: rgb(6 27 61);
    border-radius: 50px;
    min-width: 430px;
    position: relative;
    
    
}

@media (max-width: 1550px) {
    .body-point > div {
        text-align: center;
        width: 560px;
        height: 67px;
        margin: 10px;
        font-size: 30px;
        /* background: #319EA5; */
        background: rgb(6 27 61);
        border-radius: 50px;
        min-width: 430px;
        position: relative;
    }
}

@media screen and (max-width: 600px) {
    .body-point > div {
        text-align: center;
        height: 67px;
        margin: 10px;
        font-size: 30px;
        /* background: #319EA5; */
        background: rgb(6 27 61);
        border-radius: 50px;
        min-width: 300px;
        position: relative;
    }
}


.body-point p {
    width: 70%;
    padding-left: 90px;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #ffffff;
    font-size: 20px;
    text-align: start;
}

@media screen and (max-width: 600px) {
    .body-point p {
        width: 70%;
        padding-left: 70px;
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #ffffff;
        font-size: 15px;
        text-align: start;
    }
}

.body-points-circle {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    /* background: #164B4F; */
    background: #a0a0a0;
    margin: 6px;
}

.check {
    width: 28px;
    padding-top: 15px;
}


/* Transaction focus sections */
.transaction-focus {
    text-align: -webkit-center;
    margin-top: 100px;
}

.transaction-focus > h2 {
    text-align: center;
}

@media screen and (max-width: 600px) {
    .transaction-focus > h2 {
        text-align: center;
        margin-bottom: 60px;
    }
}

.transaction-focus-points {
    display: flex;
    grid-template-columns: auto auto;
    flex-wrap: wrap;
    grid-gap: 30px;
    justify-content: center;
    max-width: 1450px;
    padding: 70px;
}

@media screen and (max-width: 1280px) {
    .transaction-focus-points {
        display: flex;
        grid-template-columns: auto auto;
        flex-wrap: wrap;
        grid-gap: 30px;
        justify-content: center;
        max-width: 1450px;
        padding: 10px;
    }
    
}

@media screen and (max-width: 600px) {
    .transaction-focus-points {
        display: flex;
        grid-template-columns: auto auto;
        flex-wrap: wrap;
        grid-gap: 30px;
        justify-content: center;
        max-width: 1450px;
        padding: 5px;
    }
}

.focus-points {
    width: 600px;
    height: 60px;
    margin-left: 50px;
    margin-right: 50px;
    position: relative;
}

@media (max-width: 1550px) {
    .focus-points {
        width: 500px;
        height: 60px;
        margin-left: 50px;
        margin-right: 50px;
        position: relative;
    }
}

.focus-points p {
    top: 50%;
    transform: translateY(-60%);
    font-size: 20px;
    text-align: start;
    margin-left: 50px;
    text-align: start;
}

.focus-points-circle {
    position: absolute;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    /* background: #17B227 */
}

.transaction-check {
    color: white;
    height: 16.08px;
    width: 16px;
    padding-top: 10px;
}

/* industry focus */
.industry-focus {
    margin-top: 100px;
    background: #f4f4f4;
}

.industry-focus > h2 {
    padding-top: 50px ;
    text-align: center;
}

.industry-focus > h5 {
    margin-top: 70px;
    text-align: center;
}

.industry-grid {
    padding-top: 50px;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    grid-gap: 60px;
    flex-wrap: wrap;
    padding-bottom: 100px;
}

@media screen and (max-width: 1080px) {
    .industry-grid {
        display: flex;
        grid-template-columns: auto auto auto;
        justify-content: center;
        grid-gap: 23px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }
}