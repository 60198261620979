

.industry-icon-container {
    min-width: 200px;
    align-self: center;
    text-align: center;
}

@media screen and (max-width: 500px) {
    .industry-icon-container {
    min-width: 163px;
    align-self: center;
    text-align: center;
    }
}

.industry-icon-title {
    margin-bottom: 9px;
    text-align: center;
}

@media screen and (max-width: 500px) {
    .industry-icon-title {
        margin-bottom: 9px;
        font-size: 14px;
        text-align: center;
    }
    
}

.industry-icon-container > img {
    display: flex;
    max-width: 80px;
    max-height: 80px;
    margin: 0 auto;
}


.plain-card-3 {
    position: relative;
    /* background: #1c19c2; */
    /* background-image: url("../../assets/investment/industry focus/aerospace.png"); */
    width: 300px;
    height: 320px;
}

.blue-hue {
    position: relative;
    z-index: 5;
    width: 300px;
    height: 320px;
    /* background: linear-gradient(0deg, rgb(46 88 168 / 41%), rgba(46, 88, 168)); */
    background: linear-gradient(0deg, rgb(19 63 128 / 76%), rgb(19 63 128 / 88%));
}

.card-text {
    margin: auto;
    text-align: center;
    /* width: 1000%; */
    padding-top: 140px;
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    color: #FFFFFF;
}





/* The following CSS styling is to add the hovering effect where the card reveals into something else. 
Roman will let us know when we can go ahead with this card effect design, in the mean time we are sticking with
static cards */

/* .clip-card-container-3 {
    position: relative;
    clip-path: circle(0px at 0px 0px);
    transition: all 1s ease;
    width: 290px;
    height: 320px;
    padding-left: 10px; */
    /* border-radius: 5px; */
    /* For now this is just background color, later when we settle on pics replace with URL */
    /* background-color: aquamarine;
    background-size: cover;
} */

/* .plain-card-3:hover .clip-card-container-3 {
    clip-path: circle(100%);
} */

/* .card-details-3 {
    padding: 25px 0;
    font-weight: bold;
    font-size: 25px;
    color: rgb(17, 1, 1);
    
}

.outer-clip-details {
    font-weight: bold;
    font-size: 35px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 15px 25px;
    color: #353535;
    background-color: aquamarine;
    background-size: cover;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
 */
