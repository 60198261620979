  .logo {
    /* font-weight: bold; */
    color: var(--heading-color);
    letter-spacing: 1px;
    /* display: flex; */
    font-size: 25px;
    display: inline-flex;
    align-items: center;
    margin-right: auto;
    color: white;
  }

  .header-logo{
    position: relative;
  }

  .header-logo img { 
    width: 146px;
    position: relative;
    margin-left: 30px;
    margin-top: 10px;
  }


  header {
    background-color: #fff;
    text-align:center;
    width: 100%;
    z-index: 99;
  }

  /* hamburder css starts */

  .nav-toggle {
    display: none;
  }

  .nav-toggle-label {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 1em;
    height: 56px;
    display: flex;
    align-items: center;
  }

  /* .nav-toggle-label span = middle line */
  /* .nav-toggle-label span:: before = top line */
  /* .nav-toggle-label span:: after = bottom line */
  .nav-toggle-label span,
  .nav-toggle-label span::before,
  .nav-toggle-label span::after {
    display: block;
    background: #1a3554;
    height: 2px;
    width: 1.5em;
    border-radius: 2px;
    position: relative;
  }

  .nav-toggle-label span::before,
  .nav-toggle-label span::after {
    content: '';
    position: absolute;
  }

  .nav-toggle-label span::before {
    bottom: 7px;
  }

  .nav-toggle-label span::after {
    top: 7px;
  }

  /* hamburder css ends */

  /* nav css starts */

  nav {
    position: absolute;
    text-align: left;
    z-index: 1000;
    left: 0;
    background: #fff;
    width: 100%;
    /* initially set this with a width of 1 (100%) and 
    a height of 0, later when we toggle using nav-toggle checked,
    it will transform this to 1, 1 */
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 400ms ease-in-out;
  }

  nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    justify-content: flex-end;
  }

  nav li {
    margin-bottom: 1em;
    margin-left: 1em;
  }

  nav a {
    color: #1a3554;
    text-decoration: none;
    /* initially the opacity of the links are 0, later we 
    transition/fade them in */
    opacity: 0;
    /* upon click, the links fade in 250ms but they also have 
    a delay of 250ms (hence the 2nd 250ms we added) */
    transition: all .4s ease-in-out;
    border-bottom:#fff solid 1px;
  }

  nav a:hover {
    border-bottom:#1a3554 solid 1px;
    color: rgb(73, 73, 73);
  }

  /* the ~ looks for any preceeding sibling after (in this case it is the <nav> */
  .nav-toggle:checked ~ nav {
    transform: scale(1,1)
  }

  .nav-toggle:checked ~ nav a {
    opacity: 1;
    transition: opacity 250ms ease-in-out 250ms;
    /* display: block; */
  }




  /* From here on out, the css styling is for bigger screens (non hamburger view) */

  @media screen and (min-width: 800px) {
    .nav-toggle-label {
      display: none;
    }

    header {
      display: grid;
      /* we setup 4 columns 
        1. 1fr
        2. auto
        3. minmax()
        4. 1fr
      */
      grid-template-columns: auto auto minmax(600px, 1fr) auto;
    }

    .logo {
      /* the logo starts in column 2 and spans over to the next col */
      grid-column: 2 / span 1;
      margin-left: 50px;
    }

    nav {
      /* all: unset undoes all the mobile css above */
      all: unset;
      grid-column: 3 / 4;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    nav ul {
      display: flex;
      margin-right: 50px;
      /* justify-content: flex-end;  */
    }

    nav a {
      opacity: 1;
    }

    nav li {
      margin-bottom: 0;
    }

  }