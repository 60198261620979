.about-pg .contianer {
    max-width: 1150px;
    margin: 0 auto;
}

.about-header {
    width: 100%;
    border-bottom: 1px solid #000000;
}

.about-con {
    place-content: center;
}

.about-body-bg {
    margin-top: 30px;
    min-height: 50vh;
    text-align: center;
    background: #000000;
    color: #fff;
    background-image: url(../../assets/about/about-main-bg.png);
}


.about-body-bg span {
    color: #fff;
    font-size: 18px;
    
}

.about-body-bg h2 {
    text-align: center;
}

.about-body-bg .contianer {
    align-self: center;
    text-align:start;
}

.about-text-pg {
    padding: 0 40px 0 0;
}

.about-float-btn {
    text-align: center;
}

.about-list.space-between {
    justify-content: flex-start;
}

.about-hero-img {
    background-image: url(../../assets/about/la-homepage\(1\).jpg);
    position: relative;
    background-size: cover;
    text-align: center;
    height: 55vh;
    background-position-y: -201px;
    background-attachment: fixed;
    overflow: hidden;
    margin-bottom: 80px;
}

@media screen and (max-width: 500px) {
    .about-hero-img {
        background-image: url(../../assets/about//la-homepage\(1\).jpg);
        position: relative;
        background-size: cover;
        text-align: center;
        background-position-y: 0px;
        background-position-x: -100px;
        background-attachment:unset;
        height: 55vh;
        overflow: hidden;
        margin-bottom: 80px;
    }
}

.about-hero-shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(6, 27, 61, 0.602);
    z-index: 1;
}

.about-hero-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: fit-content;
}



.about-list ul {
    list-style: none; 
    display: grid;
    /* grid-gap: 5px; */
    grid-template-columns: repeat(2, 1fr);
  }

  .about-list li {
    margin-right: 40px;
  }
  
  .about-list ul li::before {
    content: "\2022";  
    color: #154EA3;; 
    font-weight: bold; 
    display: inline-block; 
    width: 1em; 
    margin-left: -1em; 
    font-size: 30px;
    position: relative;
    top: 3px;
    /* margin-right: 0px; */
    
  }

  .about-bottom {
    margin:50px auto !important;
}
.about-bottom img {
    margin:50px auto !important;    
    /* max-width: 45%; */
    max-height: 39vh;
}

@media (min-width:768px) { 
    .beliefs-text {
        margin-left: 100px;
    }
}

@media (min-width:768px) {
    .about-pg .left, .about-pg .right  {
        max-width: 50%;
    }
    .about-bottom {
        margin:50px auto !important;
    }
    .about-bottom img {
        margin:50px auto !important;    
        max-width: 45%;
        max-height: 39vh;
    }

    .about-text-pg.left{ 
        padding-right:40px;
    }
    .about-list.space-between {
        justify-content: space-between;
    }
    
}
